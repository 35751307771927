import React from 'react'
import { HashRouter  as Router, Switch, Route, Link } from "react-router-dom";

import Work from "./components/Work/Work"
import About from "./components/About/About"
import Simply_Salon from './components/Subpages/Simply_Salon/Simply_Salon';
import Slido from './components/Subpages/Slido/Slido';
import Na_Caji from './components/Subpages/Na_Caji/Na_Caji';
import ScrollTop from './components/ScrollTop';
import Red_Basket from './components/Subpages/Red_Basket/Red_Basket';
import Tilia from './components/Subpages/Tilia/Tilia';
import {Scrollbar} from 'smooth-scrollbar-react';
import $ from "jquery"

const App = () => {

  return (
    <>
      <Router>
        <ScrollTop />
        <Switch>
          <Route exact path="/" component={Work} />
          <Route path="/about" component={About} />
          <Route path="/simply-salon" component={Simply_Salon} />
          <Route path="/slido" component={Slido} />
          <Route path="/na-caji" component={Na_Caji} />
          <Route path="/red-basket" component={Red_Basket} />
          <Route path="/tilia" component={Tilia} />
        </Switch>
      </Router>
    </>
  )
}

export default App
