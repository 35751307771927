import React from 'react'
import "./Footer.css"

const Footer = ({color,cl}) => {
  return (
    <div className={`footer absolute z-20 bottom-5 lg:bottom-8 w-full xl:bottom-9  ${cl} `} id="footer" >
      <div className="flex justify-between align-center px-4 sm:px-10 xl:px-12 2xl:px-24" >
        <h4 className={`f1 text-2xs md:text-xs  font-bold xl:text-sm`} >© Zuzana Kopanicova 2021</h4>
        <h4 className={`f2 text-2xs md:text-xs  font-bold xl:text-sm`} ><a  className={"hover-email"} href="mailto:zuzana@kopanicova.com" >zuzana@kopanicova.com</a></h4>
        <div className="flex" >
          <h4 className={`f3 text-2xs mx-2 lg:mx-5 md:text-xs xl:text-sm  font-bold`} >
            <a href="https://www.instagram.com/zuzi_kopka/" target="_blank" >Instagram</a>  
          </h4>
          <h4 className={`f4 text-2xs md:text-xs  xl:text-sm font-bold  ml-2`} >
            <a href="https://sk.linkedin.com/in/zuzana-kopanicov%C3%A1-418a9a112" target="_blank" >Linkedin</a>
          </h4>
        </div>
     </div>
    </div>
    
  )
}

export default Footer
